import { configureStore } from '@reduxjs/toolkit';
import formRowsReducer from '../components/formRowsSlice';
import optionsReducer from '../components/OptionsSlice';

export const store = configureStore({
  reducer: {
    formRows: formRowsReducer,
    options: optionsReducer
  },
});
