import { createSlice, createAction, createSelector } from "@reduxjs/toolkit";

export const addRow = createAction("addRow");
const defaultRow = {
  material: "none",
  thickness: "none",
  colour: "none",
  width: 0,
  length: 0,
  quantity: 1,
  price: Number(0.0),
  unitPrice: 0,
  minLength: 0,
  maxLength: 0,
  minWidth: 0,
  maxWidth: 0,
  callToOrder: false,
  image: "",
  colourOptions: [],
  thicknessOptions: [],
};
const initialState = {
  rows: [],
  callToOrder: false,
};

export const formRowsSlice = createSlice({
  name: "formRows",
  initialState,
  reducers: {
    changeQuantity: (state, action) => {
      if (Number.isInteger(action.payload.row)) {
        state.rows[action.payload.row].quantity = action.payload.quantity;
      }
    },
    changePrice: (state, action) => {
      if (Number.isInteger(action.payload.row)) {
        state.rows[action.payload.row].price = Number(
          action.payload.price
        ).toFixed(2);
        state.rows[action.payload.row].image = action.payload.image;
      }
    },
    changeUnitPrice: (state, action) => {
      if (Number.isInteger(action.payload.row)) {
        state.rows[action.payload.row].unitPrice = action.payload.unitPrice;
      }
    },
    changeWidth: (state, action) => {
      if (Number.isInteger(action.payload.row)) {
        state.rows[action.payload.row].width = action.payload.width;
      }
    },
    changeLength: (state, action) => {
      if (Number.isInteger(action.payload.row)) {
        state.rows[action.payload.row].length = action.payload.length;
      }
    },
    changeMaterial: (state, action) => {
      if (action.payload.material) {
        state.rows[action.payload.row].material = action.payload.material;
      }
    },
    changeThickness: (state, action) => {
      if (action.payload.thickness) {
        state.rows[action.payload.row].thickness = action.payload.thickness;
      }
    },
    changeThicknessOptions: (state, action) => {
      if (action.payload.thicknesses) {
        console.log(action);
        state.rows[action.payload.row].thicknessOptions =
          action.payload.thicknesses;
      }
    },
    changeColour: (state, action) => {
      if (action.payload.colour) {
        state.rows[action.payload.row].colour = action.payload.colour;
        state.rows[action.payload.row].image = action.payload.image;
      }
    },
    changeColourOptions: (state, action) => {
      if (action.payload.colours) {
        state.rows[action.payload.row].colourOptions = action.payload.colours;
      }
    },
    changeMinMaxWidth: (state, action) => {
      if (action.payload.min) {
        state.rows[action.payload.row].minWidth = action.payload.min;
      }
      if (action.payload.max) {
        state.rows[action.payload.row].maxWidth = action.payload.max;
      }
    },
    changeMinMaxLength: (state, action) => {
      if (action.payload.min) {
        state.rows[action.payload.row].minLength = action.payload.min;
      }
      if (action.payload.max) {
        state.rows[action.payload.row].maxLength = action.payload.max;
      }
    },
    calculatePrice: (state, action) => {
      state.rows[action.payload.row].price =
        state.rows[action.payload.row].unitPrice *
        (state.rows[action.payload.row].width *
          state.rows[action.payload.row].length);
    },
    deleteRow: (state, action) => {
      state.rows.splice(action.payload.row, 1);
    },
    changeCallToOrder: (state, action) => {
      state.callToOrder = action.payload.callToOrder;
    },
    changeRowCallToOrder: (state, action) => {
      state.rows[action.payload.row].callToOrder =
        action.payload.callToOrder == "false" ? false : true;
    },
  },
  // The `reducers` field lets us define reducers and generate associated actions
  extraReducers: (builder) => {
    builder.addCase(addRow, (state) => {
      state.rows = state.rows.concat({ ...defaultRow });
    });
  },
});

export const {
  calculatePrice,
  changeQuantity,
  changePrice,
  changeUnitPrice,
  changeWidth,
  changeLength,
  changeMaterial,
  changeThickness,
  changeColour,
  deleteRow,
  changeMinMaxWidth,
  changeMinMaxLength,
  changeThicknessOptions,
  changeColourOptions,
  changeCallToOrder,
  changeRowCallToOrder,
} = formRowsSlice.actions;
export const selectRows = (state) => state.formRows.rows;
export const selectCallToOrder = (state) => state.formRows.callToOrder;

export default formRowsSlice.reducer;
