import React, { useEffect, useState } from 'react';
import { FormRows } from './components/FormRows';
import { Button } from './components/Button';
import {
  addRow,
  selectRows,
  selectTest
} from './components/formRowsSlice';
import './App.css';
import { useSelector, useDispatch } from 'react-redux';


function App() {
  const rows = useSelector(selectRows);
  const dispatch = useDispatch()

  return (
    <div className="App">
      <FormRows />
    </div>
  );
}

export default App;
