export const PriceIcon = ({ fill }) => {
    return(<svg width="24px" height="24px" viewBox="0 0 24 24">
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="5" transform="translate(-1181.000000, -475.000000)" fill={fill} fill-rule="nonzero">
                <g id="poundsterling" transform="translate(1181.000000, 475.000000)">
                    <path d="M9.27819499,2.56454048 C8.69913929,2.7309486 8.09482148,2.39643165 7.92841335,1.81737595 C7.76200524,1.23832025 8.09652219,0.634002436 8.67557789,0.467594313 C12.8407792,-0.729394719 17.3641724,0.393609578 20.4852814,3.51471863 C25.1715729,8.20101013 25.1715729,15.7989899 20.4852814,20.4852814 C15.7989899,25.1715729 8.20101013,25.1715729 3.51471863,20.4852814 C-1.17157287,15.7989899 -1.17157287,8.20101013 3.51471863,3.51471863 C3.94074513,3.08869213 4.63147056,3.08869213 5.05749706,3.51471863 C5.48352356,3.94074513 5.48352356,4.63147056 5.05749706,5.05749706 C1.22325855,8.89173555 1.22325855,15.1082644 5.05749706,18.9425029 C8.89173555,22.7767415 15.1082644,22.7767415 18.9425029,18.9425029 C22.7767415,15.1082644 22.7767415,8.89173555 18.9425029,5.05749706 C16.3881935,2.50318764 12.68815,1.58459296 9.27819499,2.56454048 Z" id="Path"></path>
                    <path d="M16.3636364,16.3636364 C16.9661288,16.3636364 17.4545455,16.8520531 17.4545455,17.4545455 C17.4545455,18.0570379 16.9661288,18.5454545 16.3636364,18.5454545 L7.63636364,18.5454545 C6.46995979,18.5454545 6.11738364,16.9613109 7.17368465,16.4666128 C8.77481954,15.7167525 9.03829589,14.4809363 7.88876644,12.1819485 C5.16836505,6.74131387 12.0588293,2.89241811 15.9681023,7.54068452 C16.3558957,8.0017848 16.2964687,8.68994906 15.8353684,9.07774243 C15.3742681,9.46553578 14.6861039,9.40610879 14.2983105,8.94500851 C11.9699941,6.17655645 8.33937255,8.20455969 9.84023188,11.2061856 C10.8535168,13.2326928 11.0360736,14.9962817 10.3704996,16.3636364 L16.3636364,16.3636364 Z" id="Path"></path>
                    <path d="M6.55124734,14.192971 C5.94875488,14.192971 5.46033825,13.7045543 5.46033825,13.1020619 C5.46033825,12.4995694 5.94875488,12.0111528 6.55124734,12.0111528 L13.0967019,12.0111528 C13.6991943,12.0111528 14.187611,12.4995694 14.187611,13.1020619 C14.187611,13.7045543 13.6991943,14.192971 13.0967019,14.192971 L6.55124734,14.192971 Z" id="Path"></path>
                </g>
            </g>
        </g>
    </svg>)
}