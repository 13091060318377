import { createSlice, createAction, createSelector } from '@reduxjs/toolkit';

const initialState = {
    options: false
};

export const optionsSlice = createSlice({
  name: 'options',
  initialState,
  reducers: {
    changeOptions: (state, action) => {
        console.log(action.payload.options);
        state.options = action.payload.options;
    }
  }
});

export const { changeOptions } = optionsSlice.actions;
export const selectOptions = (state) => state.options.options;

export default optionsSlice.reducer;
