import { useState } from 'react';
import styles from './Tooltip.module.css';
import ReactTooltip from 'react-tooltip';

export const Tooltip = ( props ) => {
    return (
        <div className={styles.tooltip}>
            <p style={{
                lineHeight: 0,
                textAlign: 'center'
            }} data-tip={props.content} data-html={true}>{props.icon()}</p>
            <ReactTooltip html={true} textColor={'#023047'} backgroundColor={'#5BCAE6'} arrowColor={'#5BCAE6'}  />
        </div>
    )
}